import React from "react";
import { Typography, Grid } from "@mui/material"

function Home() {


    return (
        <>
            <Grid container spacing={2} style={{ alignItems: 'center',height:'80vh' ,justifyContent: 'center'}}>
                <Grid item sm={12} md={12} lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                        <h1
                            className="left-h1"
                        >
                            Stake
                        </h1>
                        <br />
                        <h4 className="text-white">Easy, Fast & Secure!</h4>
                    </div>
                </Grid>

                <Grid item sm={12} md={12} lg={6} style={{ display: 'flex', justifyContent: 'center' }}>

                    <img src='/luv-coin.svg' alt='Coin' className="img-movement" />
                </Grid>

            </Grid>
        </>
    )
}

export default Home;
