import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import AdbIcon from "@mui/icons-material/Adb";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { Button, AppBar, Typography, Container, Toolbar, Box, IconButton, Grid, MenuItem, Menu } from "@mui/material";
import NFTStaking from './NFTStaking';
import FTStaking from './FTStaking';
import Home from './Home';

function RouterApp() {
    const wallet = useWallet();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: any) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return (
        <>

            <AppBar position="static" style={{ backgroundColor: 'transparent', boxShadow: 'none', transition: 'none', paddingTop: '5px' }}>
                <Container maxWidth={false}>
                    <Toolbar disableGutters>
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                className="logo-img"
                                style={{
                                    width: 'auto',
                                    borderRight: '3px solid white',
                                    paddingRight: '20px',
                                    height: '60px',
                                }}
                                src="/logo2.png"
                                alt=""
                            /> &nbsp;&nbsp;$LUV
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >


                                <a target="_blank" href="https://stake.luvtokenomics.com/" className="LinkStyle">
                                    <MenuItem>
                                        <Button
                                            onClick={handleCloseNavMenu}
                                            className="btnUiHead"
                                            >
                                            Stake V1
                                        </Button>
                                    </MenuItem>
                                </a>

                                <a target="_blank" href="https://stakev2.luvtokenomics.com/" className="LinkStyle">
                                    <MenuItem>
                                        <Button
                                            onClick={handleCloseNavMenu}
                                            className="btnUiHead"
                                            >
                                            Stake V2
                                        </Button>
                                    </MenuItem>
                                </a>

                                <Link to="/Staking" className="LinkStyle">
                                    <MenuItem>
                                        <Button className="btnUiHead">
                                            Stake V3
                                        </Button>
                                    </MenuItem>
                                </Link>


                                <a target="_blank" href="https://trade.dexlab.space/#/market/2rbzvorEHD5zWjFqnMvU5WbG4qRohycsFytrNTazzuMH" className="LinkStyle">
                                    <MenuItem>
                                        <Button
                                            onClick={handleCloseNavMenu}
                                            className="btnUiHead"
                                        >
                                            Trade $LUV
                                        </Button>
                                    </MenuItem>
                                </a>

                                <a target="_blank" href="https://jup.ag/swap/USDC-$LUV_J8taaG2MEBQruGWE4Hhzkrahb1nMAeSZUYBs5cmzST9k" className="LinkStyle">
                                    <MenuItem>
                                        <Button
                                            onClick={handleCloseNavMenu}
                                            className="btnUiHead"
                                            >
                                            Swap $LUV
                                        </Button>
                                    </MenuItem>
                                </a>

                                <MenuItem>
                                    {wallet?.connected ?
                                        <WalletDisconnectButton />
                                        :
                                        <WalletMultiButton />
                                    }
                                </MenuItem>

                            </Menu>
                        </Box>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                className="logo-img"
                                style={{
                                    width: '80px',
                                    borderRight: '3px solid white',
                                    paddingRight: '20px',
                                    justifyContent: 'center',
                                }}
                                src="/logo2.png"
                                alt="logo"
                            />&nbsp;&nbsp; $LUV
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', alignItems: 'center' ,gap:'1%'}}>


                            <a target="_blank" href="https://stake.luvtokenomics.com/" className="LinkStyle">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    className="btnUiHead"
                                    sx={{ my: 2, color: 'white', display: 'block' }}>
                                    Stake V1
                                </Button>
                            </a>

                            <a target="_blank" href="https://stakev2.luvtokenomics.com/" className="LinkStyle">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    className="btnUiHead"
                                    sx={{ my: 2, color: 'white', display: 'block' }}>
                                    Stake V2
                                </Button>
                            </a>

                            <Link to="/Staking" className="LinkStyle">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    className="btnUiHead"
                                    sx={{ my: 2, color: 'white', display: 'block' }}>
                                    Stake V3
                                </Button>
                            </Link>

                            <a target="_blank" href="https://trade.dexlab.space/#/market/2rbzvorEHD5zWjFqnMvU5WbG4qRohycsFytrNTazzuMH" className="LinkStyle">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    className="btnUiHead"
                                >
                                    Trade $LUV
                                </Button>
                            </a>
                            <a target="_blank" href="https://jup.ag/swap/USDC-$LUV_J8taaG2MEBQruGWE4Hhzkrahb1nMAeSZUYBs5cmzST9k" className="LinkStyle">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    className="btnUiHead"
                                    sx={{ my: 2, color: 'white', display: 'block' }}>
                                    Swap $LUV
                                </Button>
                            </a>
                            {/* <Link to="/nftStaking" className="LinkStyle">
                                <Button
                                    onClick={handleCloseNavMenu}
                                    className="btnUiHead"
                                    sx={{ my: 2, color: 'white', display: 'block' }}>
                                    NFT Staking
                                </Button>
                            </Link> */}
                            
                            {wallet?.connected ?
                                <WalletDisconnectButton className="nav1-connect-btn" />
                                :
                                <WalletMultiButton className="nav1-connect-btn" />
                            }
                            <a href="https://twitter.com/luvtokenomics?s=21&t=6V1OzdggamtkSf5wTUkSzw">
                                <img
                                    src="/twitter.svg"
                                    alt="twitter"
                                    className="social-icons"
                                />
                            </a>
                            <a href="https://discord.com/invite/cursedlabs">
                                <img
                                    src="/discord.svg"
                                    alt="discord"
                                    className="social-icons"
                                />
                            </a>

                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>


            {/* <Grid container>
                <Grid item>

                </Grid>
            </Grid> */}






            <Switch>
                <Route path="/Staking">
                    <FTStaking />
                </Route>
                {/* <Route path="/nftStaking">
                    <NFTStaking />
                </Route> */}
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </>
    );
}

export default RouterApp;