import react, { useEffect, useState, useCallback, FC } from "react";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { Button, Grid, Typography } from "@mui/material";
import { Program, web3 } from '@project-serum/anchor';
import * as anchor from '@project-serum/anchor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { Metaplex, keypairIdentity, bundlrStorage } from "@metaplex-foundation/js";
import { SystemProgram, SYSVAR_RENT_PUBKEY, Connection, clusterApiUrl, Keypair, PublicKey, LAMPORTS_PER_SOL, Transaction } from "@solana/web3.js";
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import idl from "../luv_staking.json";
import {
    IDL as BascStakingIdl,
    BascStaking as BascStakingProgram,
} from "../luv_staking";
import {
    TOKEN_PROGRAM_ID,
    ASSOCIATED_TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import CircularProgress from '@mui/material/CircularProgress';

// import {stakeNft} from "../scripts";
// import * as metaplex from '@metaplex-foundation/mpl-token-metadata'
// import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
// import * as metaplex from '@metaplex-foundation/mpl-token-metadata'

const GLOBAL_AUTHORITY_SEED = "global-authority";
const USER_POOL_SIZE = 1888;    // 8 + 8872
let rewardVault: any = null;
// let program: any = null;

const REWARD_TOKEN_MINT = new PublicKey("J8taaG2MEBQruGWE4Hhzkrahb1nMAeSZUYBs5cmzST9k");
// const USER = new PublicKey("HRqXXua5SSsr1C7pBWhtLxjD9HcreNd4ZTKJD7em7mtP");
const PROGRAM_ID = "ApfHhYSG2ew4k4PwWTzU9wazvQmZSrEzBaNyCdfi2V5";
const METAPLEX = new web3.PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');
const programId = new anchor.web3.PublicKey(PROGRAM_ID);

const ONE_PERCENT_WALLET_30 = new PublicKey('2s4A3oPer8oAfJpdFtba74KdamvEhG6oakjNUcc4ueJ4');
const ONE_PERCENT_WALLET_70 = new PublicKey('J93w6LDiNVPaKJmo2BVGGu6i1ZZi6rLSfP3QyYCumRTL');


type UserProps = {
    setCount: any,
    setCount1: any,
}


const NFTStaking = ({ setCount, setCount1 }: UserProps) => {
    const wallet = useWallet();
    
    // const connection = new Connection(clusterApiUrl("devnet"), "confirmed");
    const connection = new Connection("https://methodical-alien-diamond.solana-mainnet.quiknode.pro/915c906e06afd5bad621a099696d66253b38028a/", "confirmed");

    const Provider = new anchor.AnchorProvider(connection, wallet as any, {
        preflightCommitment: "confirmed",
    });
    //   const walletId = useWalletId()
    const { publicKey } = useWallet(); // uncomment later
    // const publicKey = new PublicKey("A3pqcbzPoZi2hDngatsKc3bVVmD7BNrHa9rH5MEDUHSB"); //comment later

    const [ftTokens, setFtTokens] = useState();
    const [walletNfts, setWalletNfts] = useState<any[]>([]);
    const [walletNfts1, setWalletNfts1] = useState<any[]>([]);
    const [requireNfts, setRequireNfts] = useState([]);
    const [usePublickey, setUsePublickey] = useState<any>();
    const [imageNfts, setImageNfts] = useState<any[]>([]);
    const [stakedNftGet, setStakedNftGet] = useState<any[]>([]);
    const [stakedNftGetImage, setStakedNftGetImage] = useState<any[]>([]);
    const [stakedState, setStakedState] = useState<Boolean>(true);
    const [loaderCheck, setLoaderCheck] = useState<Boolean>(true);
    const [checkNft, setCheckNft] = useState<Number>(0);
    const [checkNft12, setCheckNft12] = useState<Number>(0);




    // const { secondaryConnection } = useEnvironmentCtx()
    // const owner = publicKey;
    // anchor.setProvider(anchor.AnchorProvider.local(web3.clusterApiUrl('devnet')));
    // anchor.setProvider(anchor.AnchorProvider.local(web3.clusterApiUrl('mainnet-beta')));



    // const solConnection = anchor.getProvider().connection;
    const program: any = new anchor.Program(idl as anchor.Idl, programId, Provider);

    const getOwnerOfNFT = async (nftMintPk: PublicKey): Promise<PublicKey> => {
        let tokenAccountPK = await getNFTTokenAccount(nftMintPk);
        let tokenAccountInfo = await connection.getAccountInfo(tokenAccountPK);

        // console.log("nftMintPk=", nftMintPk.toBase58());
        // console.log("tokenAccountInfo =", tokenAccountInfo);

        if (tokenAccountInfo && tokenAccountInfo.data) {
            let ownerPubkey = new PublicKey(tokenAccountInfo.data.slice(32, 64))
            // console.log("ownerPubkey=", ownerPubkey.toBase58());
            return ownerPubkey;
        }
        return new PublicKey("");
    }

    const createAssociatedTokenAccountInstruction = (
        associatedTokenAddress: anchor.web3.PublicKey,
        payer: anchor.web3.PublicKey,
        walletAddress: anchor.web3.PublicKey,
        splTokenMintAddress: anchor.web3.PublicKey
    ) => {
        const keys = [
            { pubkey: payer, isSigner: true, isWritable: true },
            { pubkey: associatedTokenAddress, isSigner: false, isWritable: true },
            { pubkey: walletAddress, isSigner: false, isWritable: false },
            { pubkey: splTokenMintAddress, isSigner: false, isWritable: false },
            {
                pubkey: anchor.web3.SystemProgram.programId,
                isSigner: false,
                isWritable: false,
            },
            { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
            {
                pubkey: anchor.web3.SYSVAR_RENT_PUBKEY,
                isSigner: false,
                isWritable: false,
            },
        ];
        return new anchor.web3.TransactionInstruction({
            keys,
            programId: ASSOCIATED_TOKEN_PROGRAM_ID,
            data: Buffer.from([]),
        });
    }

    const getATokenAccountsNeedCreate = async (
        connection: anchor.web3.Connection,
        walletAddress: anchor.web3.PublicKey,
        owner: anchor.web3.PublicKey,
        nfts: anchor.web3.PublicKey[],
    ) => {
        let instructions = [], destinationAccounts = [];
        for (const mint of nfts) {
            const destinationPubkey = await getAssociatedTokenAccount(owner, mint);
            let response = await connection.getAccountInfo(destinationPubkey);
            if (!response) {
                const createATAIx = createAssociatedTokenAccountInstruction(
                    destinationPubkey,
                    walletAddress,
                    owner,
                    mint,
                );
                instructions.push(createATAIx);
            }
            destinationAccounts.push(destinationPubkey);
            if (walletAddress != owner) {
                const userAccount = await getAssociatedTokenAccount(walletAddress, mint);
                response = await connection.getAccountInfo(userAccount);
                if (!response) {
                    const createATAIx = createAssociatedTokenAccountInstruction(
                        userAccount,
                        walletAddress,
                        walletAddress,
                        mint,
                    );
                    instructions.push(createATAIx);
                }
            }
        }
        return {
            instructions,
            destinationAccounts,
        };
    }



    const getNFTTokenAccount = async (nftMintPk: anchor.web3.PublicKey): Promise<PublicKey> => {
        // console.log("getNFTTokenAccount nftMintPk=", nftMintPk.toBase58());
        const newPub = new PublicKey(nftMintPk);
        let tokenAccount: any = await connection.getProgramAccounts(
            TOKEN_PROGRAM_ID,
            {
                filters: [
                    {
                        dataSize: 165
                    },
                    {
                        memcmp: {
                            offset: 64,
                            bytes: '2'
                        }
                    },
                    {
                        memcmp: {
                            offset: 0,
                            bytes: newPub.toBase58()
                        }
                    },
                ]
            }
        );
        return tokenAccount[0].pubkey;
    }

    const getAssociatedTokenAccount = async (ownerPubkey: PublicKey, mintPk: anchor.web3.PublicKey): Promise<PublicKey> => {
        const newPub = new PublicKey(mintPk);
        let associatedTokenAccountPubkey = (await PublicKey.findProgramAddress(
            [
                ownerPubkey.toBuffer(),
                TOKEN_PROGRAM_ID.toBuffer(),
                newPub.toBuffer(), // mint address
            ],
            ASSOCIATED_TOKEN_PROGRAM_ID
        ))[0];
        return associatedTokenAccountPubkey;
    }

    // const connection = new Connection(clusterApiUrl("mainnet-beta"), "processed");
    // console.log(connection,"connection test")


    const getWallet = async () => {
        // const connection = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");
        const owner = publicKey;



        setUsePublickey(publicKey);
        // for luv token 
        const mint = new PublicKey("J8taaG2MEBQruGWE4Hhzkrahb1nMAeSZUYBs5cmzST9k");
        // const mint = new PublicKey("99n5ruAAAZ4xKzX9znzwKtintxGQHhJ8aVTFjAmHtDox");

        if (owner !== null) {
            let response = await connection.getParsedTokenAccountsByOwner(owner, { mint: mint, });

            setFtTokens(response?.value[0]?.account.data.parsed.info.tokenAmount.uiAmount);

            // console.log(response?.value[0]?.account.data.parsed.info.tokenAmount.uiAmount,"ftTokens: ")
            // console.log(ftTokens, "ftTokens: ")

        }

        setWalletNfts([]);
        setRequireNfts([]);

        // let poolAccount = await program.account.userPool.fetch(userPoolKey);
        setCheckNft(0);
        setCheckNft12(0);
        try {

            const ownerNftsData = await Metadata?.findDataByOwner(connection, publicKey || "EXn4G2sCpigdF69H6xuTMH8kiDt6KtR9J661KvHNVb7g");
            setWalletNfts(ownerNftsData);

            if (ownerNftsData.length === 0) {
                // console.log("helllo get wallet")
                setLoaderCheck(false);
            }
        } catch (e) {
            setLoaderCheck(false);
            // console.log("connection error", e);
        }



    };

    const scrapNft = () => {
        const randomArrayForNft: any = [];
        let checkToken: number = 0;
        let checkToken12: number = 0;
        setLoaderCheck(true);



        // while (imageNfts.length) { 
        //     setImageNfts(imageNfts.pop() )
        // }

        // setImageNfts([]);
        //     console.log(imageNfts,"ftTokens:123 ")



        walletNfts.map((data) => {

            if (data?.data?.creators?.length > 0 && data?.data?.creators[0].address === 'GHdEoGnLuDWTGFDbzrCTer9S6SUJ773dMtPyihZCmtc6'
                // || data!.data!.creators[0].address === 'DBChou4thiPQxDCS6tAZHJD84d4Btu3mWoieUsFgwCfJ'
            ) {
                setCheckNft(checkToken = checkToken + 1);
            }

            if (data?.data?.creators?.length > 0 && data?.data?.creators[0].address === 'DBChou4thiPQxDCS6tAZHJD84d4Btu3mWoieUsFgwCfJ'
                // || data!.data!.creators[0].address === 'DBChou4thiPQxDCS6tAZHJD84d4Btu3mWoieUsFgwCfJ'
            ) {
                setCheckNft12(checkToken12 = checkToken12 + 1);
            }

            // console.log("this is wallet data")
            if ((data?.data?.creators?.length > 0 && data?.data?.creators[0].address === '9PeX9Q53GqFXuYvSQ5X5qBDy6GnkHKbHjLcNK6wa3hKm') || (data?.data?.creators?.length > 0 && data?.data?.creators[0].address === '5xkJYJKDBVdvsnm3gHQ1PQy3BtUrf8wH8yJLi65wphSR')) {
                randomArrayForNft.push(data);
            }
        })

        // console.log("123")

        // if (randomArrayForNft.length === 0) {
        setLoaderCheck(false);
        setLoaderCheck(false);
        // console.log("No random");

        // }

        setRequireNfts(randomArrayForNft);

    }

    const imageNft = async () => {
        // console.log( "No random 123");
        if (requireNfts.length > 0) {
            setLoaderCheck(true);
            setLoaderCheck(true);
        }


        const imageArrayForNft: any = [];
        await Promise.all(
            requireNfts?.map(async (obj: any) => {
                const response = await fetch(obj?.data?.uri);
                // console.log("response: ", response);
                const result = await response.json();

                imageArrayForNft.push({
                    cmId: obj?.data.creators[0].address,
                    name: obj?.data.name,
                    mint: obj?.mint,
                    src: result.image,
                    url: obj?.data.uri,
                });
                // console.log("Image", result.image);
            })
        );

        // console.log("1231")

        setImageNfts(imageArrayForNft);

        // setLoaderCheck(false);

    }

    useEffect(() => { getWallet() }, [publicKey, stakedState])

    useEffect(() => {
        setLoaderCheck(true);
        // setTimeout(function () {
        //     setLoaderCheck(false)
        // }
        //     , 4000);
    }, [stakedState])

    useEffect(() => {
        setCheckNft(0);
        setCheckNft12(0);
        if (walletNfts.length > 0)
            scrapNft();
        // imageNft();
    }, [walletNfts, publicKey, stakedState]);


    useEffect(() => {

        // if (requireNfts.length > 0)
        imageNft();
    }, [requireNfts, stakedState]);


    const initUserPool = async (
        userAddress: PublicKey,
    ) => {

        const [poolAddress] = await anchor.web3.PublicKey.findProgramAddress(
            [userAddress.toBuffer(), anchor.utils.bytes.utf8.encode("user-pool")],
            programId,
        );
        // console.log("poolAddress: ", poolAddress.toString());

        let userPoolKey = await PublicKey.createWithSeed(
            userAddress,
            "user-pool",
            programId,
        );
        // console.log("userPoolKey: ", userPoolKey.toString());
        // return;
        // console.log(USER_POOL_SIZE);

        const tx = new Transaction();
        let ix = SystemProgram.createAccountWithSeed({
            fromPubkey: userAddress,
            basePubkey: userAddress,
            seed: "user-pool",
            newAccountPubkey: userPoolKey,
            lamports: await connection.getMinimumBalanceForRentExemption(USER_POOL_SIZE),
            space: USER_POOL_SIZE,
            programId,
        });

        // console.log("ix: ", ix);

        tx.add(ix)


        tx.add(
            await program.instruction.initializeUserPool(
                {
                    accounts: {
                        userPool: userPoolKey,
                        owner: userAddress,
                    },
                    instructions: [
                    ],
                    signers: []
                }
            )
        );

        const txId = await wallet.sendTransaction(tx, connection);
        // await connection.confirmTransaction(txId, "finalized");





        // const tx = await program.instruction.initializeUserPool(
        //     {
        //         accounts: {
        //             userPool: userPoolKey,
        //             owner: userAddress,
        //         },
        //         instructions: [
        //             ix
        //         ],
        //         signers: []
        //     }
        // );
        await connection.confirmTransaction(txId, "finalized");

        // console.log("Your transaction signature", tx);
        let poolAccount = await program.account.userPool.fetch(userPoolKey);

        // console.log('Owner of initialized pool = ', poolAccount.owner.toBase58());
    }

    const getMetadata = async (mint: anchor.web3.PublicKey): Promise<PublicKey> => {
        const newPub = new PublicKey(mint);
        return (
            await PublicKey.findProgramAddress([Buffer.from('metadata'), METAPLEX.toBuffer(), newPub.toBuffer()], METAPLEX)
        )[0];
    };




    const stakeNft = async (userAddress: PublicKey, mint: PublicKey, cmId: any) => {
        let walletNft1: any = [];
        let cozy = 0;
        let cured = 0;

        let cozyStaked = 0;
        let curedStaked = 0;

        const StakedNftPoolGet = async (userAddress: PublicKey) => {
            const arr: any = [];
            let userPoolKey = await PublicKey.createWithSeed(
                userAddress,
                "user-pool",
                programId,
            );
            let staked: any = [];
            try {
                let poolState = await program.account.userPool.fetch(userPoolKey);
                staked = (poolState).stakedNfts.slice(
                    0,
                    (poolState).actualNftCount.toNumber()
                );
            } catch (e) {
                // console.log(e, "lopipos")
            }
            const data: any = staked.map(async (e: any) => {
                const luvNft: any = await Metadata?.findByMint(connection, new anchor.web3.PublicKey(e.mint))
                const xyz = await Promise.resolve(luvNft);
                arr.push(xyz)
            })

            stakedImageNft();
            setStakedNftGet(arr)

            stakedNftGet?.map((e: any) => {
                if (e.data?.data?.creators?.length > 0 && e.data?.data?.creators[0].address === '9PeX9Q53GqFXuYvSQ5X5qBDy6GnkHKbHjLcNK6wa3hKm') {
                    cozy = cozy + 1;
                    cozyStaked = cozyStaked + 1;
                }
                if (e.data?.data?.creators?.length > 0 && e.data?.data?.creators[0].address === '5xkJYJKDBVdvsnm3gHQ1PQy3BtUrf8wH8yJLi65wphSR') {
                    cured = cured + 1;
                    curedStaked = curedStaked + 1;
                }
            })
            // console.log("this is count");
            setCount(cozy);
            setCount1(cured);
        }
        StakedNftPoolGet(userAddress);



        // setCheckNft(0);
        // setCheckNft12(0);



        try {
            const ownerNftsData = await Metadata?.findDataByOwner(connection, userAddress || "EXn4G2sCpigdF69H6xuTMH8kiDt6KtR9J661KvHNVb7g");

            walletNft1 = ownerNftsData;
        } catch (e) {
            // console.log(e, "connection test");
        }
        let checkToken: number = 0;
        let checkToken12: number = 0;


        walletNft1.map((data: any) => {

            if (data?.data?.creators?.length > 0 && data!.data!.creators[0].address === 'GHdEoGnLuDWTGFDbzrCTer9S6SUJ773dMtPyihZCmtc6'
            ) {
                //cozy
                checkToken = checkToken + 1;
            }

            if (data?.data?.creators?.length > 0 && data!.data!.creators[0].address === 'DBChou4thiPQxDCS6tAZHJD84d4Btu3mWoieUsFgwCfJ'
            ) {
                // cursed 
                checkToken12 = checkToken12 + 1;
            }
        })



        if (cmId == '9PeX9Q53GqFXuYvSQ5X5qBDy6GnkHKbHjLcNK6wa3hKm') {
            if (checkToken == 0) {
                toast.error("Error: No Cozy Corner")
                return;
            }

            // console.log(checkToken, "this is count cozy", cozyStaked, "nbh", (checkToken - 1) < cozyStaked)

            if ((checkToken - 1) < cozyStaked) {
                toast.error("Error: You don't have enough Cozy Corner  ")
                return;
            }
        }

        if (cmId == '5xkJYJKDBVdvsnm3gHQ1PQy3BtUrf8wH8yJLi65wphSR') {
            if (checkToken12 == 0) {
                toast.error("Error: No Cursed Token")
                return;
            }

            // console.log(checkToken12, "this is count stakeCured", curedStaked, "nbh", (checkToken12 - 1) < curedStaked)

            if ((checkToken12 - 1) < curedStaked) {

                toast.error("Error: You don't have enough Cursed Token")
                return;
            }
        }


        if (ftTokens === 0) {
            toast.error("Error: You don't have any LUV Token ")
            return;
        }
        let transaction = [];
        let userTokenAccount = await getAssociatedTokenAccount(userAddress, mint);
        let accountOfNFT = await getNFTTokenAccount(mint);
        const nft = new PublicKey(mint)
        // console.log(nft.toString(),"this is nft")
        if (userTokenAccount.toBase58() != accountOfNFT.toBase58()) {
            let nftOwner = await getOwnerOfNFT(mint);
            if (nftOwner.toBase58() == userAddress.toBase58()) userTokenAccount = accountOfNFT;
            else {
                // console.log('Error: Nft is not owned by user');
                return;
            }
        }

        // console.log("BASC NFT = ", mint.toBase58(), userTokenAccount.toBase58());

        const [globalAuthority, bump] = await PublicKey.findProgramAddress(
            [Buffer.from(GLOBAL_AUTHORITY_SEED)],
            programId
        );
        // console.log("globalAuthority: ", globalAuthority.toString())



        // console.log("Dest NFT Account = ", destinationAccounts[0].toBase58())
        let userPoolKey = await PublicKey.createWithSeed(
            userAddress,
            "user-pool",
            programId,
        );

        try {
            let poolState = await program.account.userPool.fetch(userPoolKey);
            // console.log("PoolState: ", poolState.owner.toString());
            // return poolState as UserPool;
        } catch {
            // return null;
        }


        let poolAccount = await connection.getAccountInfo(userPoolKey);
        if (poolAccount === null || poolAccount.data === null) {
            await initUserPool(userAddress);
        }

        const metadata = await getMetadata(mint);
        // console.log("Metadata=", metadata.toBase58());

        const tx = new Transaction();

        let { instructions, destinationAccounts }: any = await getATokenAccountsNeedCreate(
            connection,
            userAddress,
            globalAuthority,
            [nft]
        );

        // console.log("instructions: ", destinationAccounts[0].toString());

        if (instructions.length > 0) tx.add(instructions[0]);

        // tx.add(
        //     SystemProgram.transfer({
        //         fromPubkey: userAddress,
        //         toPubkey: ONE_PERCENT_WALLET_30,
        //         lamports: 6000000,
        //     }),
        //     SystemProgram.transfer({
        //         fromPubkey: userAddress,
        //         toPubkey: ONE_PERCENT_WALLET_70,
        //         lamports: 14000000,
        //     }),
        // );

        try {

            tx.add(
                program.instruction.stakeNftToPool(
                    bump, {
                    accounts: {
                        owner: userAddress,
                        userPool: userPoolKey,
                        globalAuthority,
                        userTokenAccount,
                        destNftTokenAccount: destinationAccounts[0],
                        nftMint: nft,
                        mintMetadata: metadata,
                        tokenProgram: TOKEN_PROGRAM_ID,
                        tokenMetadataProgram: METAPLEX,
                    },
                    instructions: [],
                    // ...instructions

                    signers: [],
                }
                )
            );

            const txId = await wallet.sendTransaction(tx, connection);
            await connection.confirmTransaction(txId, "singleGossip");
            toast.success("Successfully Staked");

            getWallet();
            StakedNftPoolGet(userAddress);
        } catch (err: any) {
            toast.error(`Error: Token Stake Failed. ${err.message}`)
        }

    }



    const StakedNftPoolGet = async (userAddress: PublicKey) => {
        const arr: any = [];
        setLoaderCheck(true);


        let userPoolKey = await PublicKey.createWithSeed(
            userAddress,
            "user-pool",
            programId,
        );

        let staked: any = [];

        try {
            // let poolState = await program.account.userPool.all()
            let poolState = await program.account.userPool.fetch(userPoolKey);
            // console.log(poolState.actualNftCount.toNumber(), "poolState123")
            staked = (poolState).stakedNfts.slice(
                0,
                (poolState).actualNftCount.toNumber()
            );

            // return poolState as UserPool;
        } catch (e) {
            // return null;
            // console.log(e, "lopipos")
        }
        // console.log(staked.length, "staked12")
        // console.log(staked[0].lastClaimedTime.toNumber(), "program:12")




        const data: any = staked.map(async (e: any) => {

            const luvNft: any = await Metadata?.findByMint(connection, new anchor.web3.PublicKey(e.mint))
            const xyz = await Promise.resolve(luvNft);
            arr.push(xyz)

            // console.log("PoolState: ", xyz);

        })

        stakedImageNft();
        setStakedNftGet(arr)

        let cozy = 0;
        let cured = 0;

        stakedNftGet?.map((e: any) => {
            if (e.data?.data?.creators?.length > 0 && e.data.data.creators[0].address === '9PeX9Q53GqFXuYvSQ5X5qBDy6GnkHKbHjLcNK6wa3hKm') {
                cozy = cozy + 1;
            }
            if (e.data?.data?.creators?.length > 0 && e.data.data.creators[0].address === '5xkJYJKDBVdvsnm3gHQ1PQy3BtUrf8wH8yJLi65wphSR') {
                cured = cured + 1;
            }
        })

        // console.log("this is count123");

        setCount(cozy);
        setCount1(cured);


    }



    const stakedImageNft = async () => {
        const imageArrayForNft: any = [];
        await Promise.all(
            stakedNftGet?.map(async (obj: any) => {
                const response = await fetch(obj?.data.data.uri);
                // console.log("response: ", obj.data.data);
                // console.log(stakedNftGet, "stakedNftGet12");
                const result = await response.json();

                imageArrayForNft.push({
                    cmId: obj?.data.data.creators[0].address,
                    name: obj?.data.data.name,
                    mint: obj?.data.mint,
                    src: result.image,
                    url: obj?.data.data.uri,
                });
            })
        );
        // console.log("this is image data");
        setStakedNftGetImage(imageArrayForNft);
    }

    useEffect(() => {
        if (stakedNftGet.length > 0)
            stakedImageNft();
    }, [stakedNftGet
        , stakedState
    ]);


    useEffect(() => {

        if (publicKey) {
            StakedNftPoolGet(publicKey);
        }

    }, [publicKey, stakedState])


    // Unstake NFT Function start
    // Unstake NFT Function start
    // Unstake NFT Function start
    // Unstake NFT Function start
    // Unstake NFT Function start



    const withdrawNft = async (userAddress: PublicKey, mint: anchor.web3.PublicKey, cmId: any) => {
        let walletNft1: any = [];
        let cozy = 0;
        let cured = 0;

        let cozyStaked = 0;
        let curedStaked = 0;

        const StakedNftPoolGet = async (userAddress: PublicKey) => {
            const arr: any = [];
            let userPoolKey = await PublicKey.createWithSeed(
                userAddress,
                "user-pool",
                programId,
            );
            let staked: any = [];
            try {
                let poolState = await program.account.userPool.fetch(userPoolKey);
                staked = (poolState).stakedNfts.slice(
                    0,
                    (poolState).actualNftCount.toNumber()
                );
            } catch (e) {
                // console.log(e, "lopipos")
            }
            const data: any = staked.map(async (e: any) => {
                const luvNft: any = await Metadata?.findByMint(connection, new anchor.web3.PublicKey(e.mint))
                const xyz = await Promise.resolve(luvNft);
                arr.push(xyz)
            })

            stakedImageNft();
            setStakedNftGet(arr)

            stakedNftGet?.map((e: any) => {
                if (e.data?.data?.creators?.length > 0 && e.data.data.creators[0].address === '9PeX9Q53GqFXuYvSQ5X5qBDy6GnkHKbHjLcNK6wa3hKm') {
                    cozy = cozy + 1;
                    cozyStaked = cozyStaked + 1;
                }
                if (e.data?.data?.creators?.length > 0 && e.data.data.creators[0].address === '5xkJYJKDBVdvsnm3gHQ1PQy3BtUrf8wH8yJLi65wphSR') {
                    cured = cured + 1;
                    curedStaked = curedStaked + 1;
                }
            })
            // console.log("this is count1234");
            setCount(cozy);
            setCount1(cured);
        }
        StakedNftPoolGet(userAddress);

        try {
            const ownerNftsData = await Metadata?.findDataByOwner(connection, userAddress || "EXn4G2sCpigdF69H6xuTMH8kiDt6KtR9J661KvHNVb7g");
            // console.log(ownerNftsData, "connection test12");

            walletNft1 = ownerNftsData;
        } catch (e) {
            // console.log(e, "error yo get tokens");
        }

        // setCheckNft(0);
        // setCheckNft12(0);


        let checkToken: number = 0;
        let checkToken12: number = 0;

        // console.log(checkToken, "connection test");

        walletNft1.map((data: any) => {


            if (data?.data?.creators?.length > 0 && data!.data!.creators[0].address === 'GHdEoGnLuDWTGFDbzrCTer9S6SUJ773dMtPyihZCmtc6'
                // || data!.data!.creators[0].address === 'DBChou4thiPQxDCS6tAZHJD84d4Btu3mWoieUsFgwCfJ'
            ) {
                // console.log(data, "connection test");

                checkToken = checkToken + 1;
            }

            if (data?.data?.creators?.length > 0 && data!.data!.creators[0].address === 'DBChou4thiPQxDCS6tAZHJD84d4Btu3mWoieUsFgwCfJ'
                // || data!.data!.creators[0].address === 'DBChou4thiPQxDCS6tAZHJD84d4Btu3mWoieUsFgwCfJ'
            ) {
                // setCheckNft12(
                checkToken12 = checkToken12 + 1;
            }
        })

        //console.log(checkToken12, "connection test1");




        //  //console.log(cmId, "test Cmid");
        if (cmId == '9PeX9Q53GqFXuYvSQ5X5qBDy6GnkHKbHjLcNK6wa3hKm') {
            if (checkToken == 0) {
                // console.log(checkNft,"Error No Cozy Corner");
                toast.error("Error: No Cozy Corner")
                return;
            }

            if ((checkToken) < cozyStaked) {
                toast.error("Error: You don't have enough Cozy Corner")
                return;
            }
        }

        if (cmId == '5xkJYJKDBVdvsnm3gHQ1PQy3BtUrf8wH8yJLi65wphSR') {
            if (checkToken12 == 0) {
                // console.log(checkNft12, "Error No cursed token");
                // console.log(checkNft,"Error No Cozy Corner");
                toast.error("Error: No Cursed Token")
                return;
            }

            if ((checkToken12) < curedStaked) {

                toast.error("Error: You don't have enough Cursed Tokens")
                return;
            }
        }

        // console.log(checkToken12, "connection test2");


        let retUser = await getATokenAccountsNeedCreate(
            connection,
            userAddress,
            userAddress,
            [mint]
        );
        let userTokenAccount = retUser.destinationAccounts[0];
        // console.log("BASC NFT = ", mint.toBase58(), userTokenAccount.toBase58());

        const [globalAuthority, bump] = await PublicKey.findProgramAddress(
            [Buffer.from(GLOBAL_AUTHORITY_SEED)],
            programId
        );

        let { instructions, destinationAccounts } = await getATokenAccountsNeedCreate(
            connection,
            userAddress,
            globalAuthority,
            [mint]
        );
        rewardVault = await getAssociatedTokenAccount(globalAuthority, REWARD_TOKEN_MINT);


        // console.log("Dest NFT Account = ", destinationAccounts[0].toBase58());

        let userPoolKey = await PublicKey.createWithSeed(
            userAddress,
            "user-pool",
            programId,
        );

        let ret = await getATokenAccountsNeedCreate(
            connection,
            userAddress,
            userAddress,
            [REWARD_TOKEN_MINT]
        );

        const metadata = await getMetadata(mint);

        try {
            const tx = await program.rpc.withdrawNftFromPool(
                bump, {
                accounts: {
                    owner: userAddress,
                    userPool: userPoolKey,
                    globalAuthority,
                    userTokenAccount,
                    destNftTokenAccount: destinationAccounts[0],
                    nftMint: mint,
                    mintMetadata: metadata,
                    rewardVault,
                    userRewardAccount: ret.destinationAccounts[0],
                    tokenProgram: TOKEN_PROGRAM_ID,
                },
                instructions: [
                    ...retUser.instructions,
                    ...ret.instructions,

                    // SystemProgram.transfer({
                    //     fromPubkey: userAddress,
                    //     toPubkey: ONE_PERCENT_WALLET_30,
                    //     lamports: 6000000,
                    // }),
                    // SystemProgram.transfer({
                    //     fromPubkey: userAddress,
                    //     toPubkey: ONE_PERCENT_WALLET_70,
                    //     lamports: 14000000,
                    // }),
                ],
                signers: [],
            }
            );
            let result = await connection.confirmTransaction(tx);
            toast.success("Successfully Unstaked");

            if (result) {
                StakedNftPoolGet(userAddress);
                getWallet();
            }
        }
        catch (err: any) {
            toast.error(`Error: Token UnStake Failed. ${err.message}`)
        }


        //  console.log("Signature: ", tx);
    }


    return (
        <>
            <ToastContainer />

            {/* <Grid container justifyContent="space-evenly" alignItems="center" style={{ gap: 1 }}>

                <Grid item xs={12} sm={12} md={6} lg={3} className="MainInforBox" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", fontFamily: 'Poppins' }}>
                    Your Wallet
                    <br />
                    <>
                        <p
                            style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}
                        >
                            {wallet?.publicKey?.toString().slice(0, 6)}. . . .{wallet?.publicKey?.toString().slice(38)}
                        </p>
                    </>
                </Grid>
            </Grid> */}
            <div className='btnFTpageMain'>
                <Button variant='contained'
                    onClick={() => setStakedState(true)}
                    style={stakedState ?
                        {
                            fontWeight: '600', fontSize: '20px', color: 'white', fontFamily: 'Poppins',
                            backgroundColor: '#e83f88', maxWidth: '330px', width: '100%', borderRadius: '30px'
                        }
                        :
                        {
                            fontWeight: '600', fontSize: '20px', color: 'white', fontFamily: 'Poppins',
                            backgroundColor: '#C390A2', maxWidth: '330px', width: '100%', borderRadius: '30px'
                        }
                    }
                >
                    Stake Tokens
                </Button>

                <Button variant='contained'
                    style={stakedState ?
                        {
                            fontWeight: '600', fontSize: '20px', color: 'white', fontFamily: 'Poppins',
                            backgroundColor: '#C390A2', maxWidth: '330px', width: '100%', borderRadius: '30px'
                        }
                        :
                        {
                            fontWeight: '600', fontSize: '20px', color: 'white', fontFamily: 'Poppins',
                            backgroundColor: '#e83f88', maxWidth: '330px', width: '100%', borderRadius: '30px'
                        }
                    }
                    onClick={() => setStakedState(false)}
                >
                    View Staked Tokens
                </Button>
            </div>
            {wallet?.connected ?
                <div
                    style={{
                        minHeight: '400px'
                        // ,overflow: 'auto',maxHeight: '400px'
                    }}
                >


                    {stakedState ?
                        <>
                            {/* <fieldset style={{ borderRadius: '20px' }}> */}
                            <Grid container style={{ justifyContent: 'center' }}>
                                {imageNfts && imageNfts.length != 0 ?

                                    //  &&
                                    <>
                                        {/* {console.log(imageNfts, "hello imageNfts ")} */}

                                        {imageNfts?.map((data) => (
                                            <>
                                                {/* {console.log(data.cmId,"cmId")} */}

                                                <Grid item xs={12} sm={6} md={4} lg={3} style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <img src={data.src} alt="nft image" style={{ height: "300px", width: '300px', borderRadius: "20px" }} />
                                                    <Typography variant="h6" style={{ color: 'white', marginBottom: '10px', marginTop: '10px', fontFamily: 'Poppins' }}>{data.name} </Typography>
                                                    <Button
                                                        style={{ fontWeight: '600', marginTop: '15px', marginBottom: '25px', fontSize: '20px', color: 'white', fontFamily: 'Poppins', backgroundColor: '#C390A2', maxWidth: '250px', width: '100%', borderRadius: '30px' }}
                                                        variant='contained'
                                                        onClick={() => { stakeNft(usePublickey, data.mint, data.cmId) }}
                                                    >
                                                        Stake
                                                    </Button>
                                                </Grid>
                                            </>
                                        ))}
                                    </>
                                    :
                                    <>
                                        {loaderCheck ?
                                            <CircularProgress style={{ color: 'white' }} />
                                            :
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                                <Typography variant="h5" style={{ color: 'white', marginTop: '20px', marginBottom: '10px', fontFamily: 'Poppins' }}>You don't have any token</Typography>
                                            </div>
                                        }
                                    </>
                                }
                            </Grid>
                            {/* </fieldset> */}
                        </>
                        :
                        <>
                            {stakedNftGetImage.length > 0 ?
                                <>

                                    <Grid container style={{ justifyContent: 'center' }}>
                                        {stakedNftGetImage?.map((data: any) => (
                                            <>
                                                <Grid item xs={12} sm={6} md={4} lg={3} style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                    <img src={data.src} alt="nft image" style={{ height: "300px", width: '300px', borderRadius: "20px" }} />
                                                    <Typography variant="h6" style={{ color: 'white', marginBottom: '10px', marginTop: '10px', fontFamily: 'Poppins' }}>{data.name} </Typography>
                                                    <Button
                                                        variant='contained'
                                                        style={{ fontWeight: '600', marginTop: '25px', marginBottom: '25px', fontSize: '20px', color: 'white', fontFamily: 'Poppins', backgroundColor: '#C390A2', maxWidth: '250px', width: '100%', borderRadius: '30px' }}
                                                        onClick={() => { withdrawNft(usePublickey, new PublicKey(data.mint), data.cmId) }}
                                                    >
                                                        UnStake
                                                    </Button>
                                                </Grid>
                                            </>
                                        ))}
                                    </Grid>

                                </>
                                :
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="h5" style={{ color: 'white', marginTop: '20px', marginBottom: '10px', fontFamily: 'Poppins' }}>No tokens are staked </Typography>
                                </div>
                            }
                        </>
                    }
                </div>
                :
                <>

                </>
            }
        </>
    );

}

NFTStaking.defaultProps = {
    setCount: 0,
    setCount1: 0,
}


export default NFTStaking;