import React, { useCallback, useEffect, useState } from "react";
import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl, Connection, PublicKey, SystemProgram } from "@solana/web3.js";
import NFTStaking from "./NFTStaking";
import * as bs58 from "bs58";
import Countdown from 'react-countdown';
import { Grid, Typography, Button, TextField } from "@mui/material";
import * as anchor from '@project-serum/anchor';
import {
    TOKEN_PROGRAM_ID,
    ASSOCIATED_TOKEN_PROGRAM_ID
} from "@solana/spl-token";
import idl from "../luv_staking.json";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const USER_POOL_SIZE = 1888;    // 8 + 8872
const USER_PAIR_POOL_SIZE = 6720;     // 8 + 6712
const GLOBAL_AUTHORITY_SEED = "global-authority";

const REWARD_TOKEN_MINT = new PublicKey("J8taaG2MEBQruGWE4Hhzkrahb1nMAeSZUYBs5cmzST9k");

const ONE_PERCENT_WALLET_30 = new PublicKey('2s4A3oPer8oAfJpdFtba74KdamvEhG6oakjNUcc4ueJ4');
const ONE_PERCENT_WALLET_70 = new PublicKey('J93w6LDiNVPaKJmo2BVGGu6i1ZZi6rLSfP3QyYCumRTL');


// const USER = new PublicKey("HRqXXua5SSsr1C7pBWhtLxjD9HcreNd4ZTKJD7em7mtP");
const PROGRAM_ID = "ApfHhYSG2ew4k4PwWTzU9wazvQmZSrEzBaNyCdfi2V5";
const programId = new anchor.web3.PublicKey(PROGRAM_ID);



function FTStaking() {


    const { publicKey } = useWallet(); // uncomment later
    // const publicKey = new PublicKey("A3pqcbzPoZi2hDngatsKc3bVVmD7BNrHa9rH5MEDUHSB"); //comment later
    const wallet = useWallet();
    // const connection = new Connection(clusterApiUrl("devnet"), "processed");
    //old rpc: https://green-polished-fire.solana-mainnet.quiknode.pro/0b8461a7cacccb991a0872d883157a01b7698b93/
    const connection = new Connection("https://methodical-alien-diamond.solana-mainnet.quiknode.pro/915c906e06afd5bad621a099696d66253b38028a/", "processed");


    const [value, setValue] = useState(1);

    const [ftTokens, setFtTokens] = useState();
    const [ftStakedToken, setFtStakedToken] = useState<any>();
    const [ftTokensMintADD, setFtTokensMintAdd] = useState<any>();
    const [ftTokensPubKeyADD, setFtTokensPubKeyAdd] = useState<any>();

    const [numberToken, setNumberToken] = useState<any>();
    const [stakedNfts, setStakedNfts] = useState<number>(0);

    const [count, setCount] = useState<number>(0);
    const [count1, setCount1] = useState<number>(0);

    const [stakedNftTimeStamp, setStakedNftTimeStamp] = useState<number>(0);


    // const [apyStore, setApyRateSum] = useState<number>(0);
    // const [apyRateSum, setApyRateSum] = useState<number>(0);




    let abc = 0;



    const [stakedState, setStakedState] = useState<Boolean>(true);
    const Provider = new anchor.AnchorProvider(connection, wallet as any, {
        preflightCommitment: "confirmed",
    });

    const program: any = new anchor.Program(idl as anchor.Idl, programId, Provider);


    const getWallet = async () => {
        // const connection = new Connection(clusterApiUrl("mainnet-beta"), "processed");
        const owner = publicKey; //uncomment later
        // const owner = new PublicKey("7wqafajLyWVNuvMojWBcvyqyGHyS9e4RhQnH1VAhYmwM") // comment later
        // for luv token 
        const mint = new PublicKey("J8taaG2MEBQruGWE4Hhzkrahb1nMAeSZUYBs5cmzST9k");

        // const mint = new PublicKey("99n5ruAAAZ4xKzX9znzwKtintxGQHhJ8aVTFjAmHtDox");
        if (owner !== null) {
            let response = await connection.getParsedTokenAccountsByOwner(owner, { mint: mint, });
            // console.log(mint.toString(),"mint");
            // console.log("hellooooo")

            setFtTokensPubKeyAdd(owner);

            if (mint) {
                setFtTokensMintAdd(mint);
            }

            setFtTokens(response?.value[0]?.account.data.parsed.info.tokenAmount.uiAmount);
        }

        // console.log(response.value[0].account.data.parsed.info.tokenAmount.uiAmount, "Tokens of client ");
        // console.log(response.value[0].account.data.parsed.info, "connected owner");
    };

    useEffect(() => {
        getWallet();

    }, [publicKey, ftStakedToken])





    // stake FT luv start 
    // stake FT luv start 
    // stake FT luv start 
    // stake FT luv start 
    // stake FT luv start 



    const stakeLuv = async (userAddress: PublicKey, mint: PublicKey, amount: any, lock: any) => {

        if (lock == 1 && (amount < 0
            // || amount > 99
        )) {
            toast.error(`Error: You trying to stake ${amount} LUV tokens`);
            return;
        }
        if (lock == 2 && (
            // amount > 999 ||
            amount < 100)) {
            toast.error("Error: In 60 days lock period LUV token must more than 100")
            return;
        }
        if (lock == 3 && amount < 1000) {
            toast.error("Error: In 90 days lock period LUV token must more than 1000")
            return;
        }
        //  console.log(ftTokens, "ftTokens12")

        if (ftTokens == undefined || ftTokens <= 0.9) {
            toast.error("Error: No LUV Token in wallet")
            return;
        }

        const [globalAuthority, bump] = await PublicKey.findProgramAddress(
            [Buffer.from(GLOBAL_AUTHORITY_SEED)],
            programId
        );
        // console.log(globalAuthority,"globalAuthority: 1")
        let rewardVault = await getAssociatedTokenAccount(globalAuthority, REWARD_TOKEN_MINT);

        let userTokenAccount = await getAssociatedTokenAccount(userAddress, REWARD_TOKEN_MINT);

        let userPoolKey = await PublicKey.createWithSeed(
            userAddress,
            "user-pool",
            programId,
        );

        let poolAccount = await connection.getAccountInfo(userPoolKey);
        // await initUserPool(userAddress);
        if (poolAccount === null || poolAccount.data === null) {

            await initUserPool(userAddress);
            // return;
        }

        // const ONE_PERCENT_WALLET = new PublicKey('41NhinBZAQHsGcqC6pPYpy97D255nYC2ZPnWVaLqacRJ');



        // return;

        // const metadata = await getMetadata(mint);
        // console.log("Metadata=", metadata.toBase58());
        try {
            const tx = await program.rpc.stakeLuvToPool(
                bump, new anchor.BN(amount * 1000000000), new anchor.BN(lock), {
                accounts: {
                    owner: userAddress,
                    userPool: userPoolKey,
                    globalAuthority,
                    userTokenAccount,
                    rewardVault,
                    tokenProgram: TOKEN_PROGRAM_ID,
                },
                instructions: [
                    // SystemProgram.transfer({
                    //     fromPubkey: userAddress,
                    //     toPubkey: ONE_PERCENT_WALLET_30,
                    //     lamports: 6000000,
                    // }),
                    // SystemProgram.transfer({
                    //     fromPubkey: userAddress,
                    //     toPubkey: ONE_PERCENT_WALLET_70,
                    //     lamports: 14000000,
                    // }),
                ],
                signers: [],
            }
            );
            await connection.confirmTransaction(tx, "singleGossip");
            toast.success("Successfully Staked");
        } catch (e: any) {
            // console.log(`error ${e}`);
            toast.error(`Error: LUV Stake Failed. ${e.message}`)
        }


        if (publicKey) {
            getWallet();
            StakedNftPoolGet(publicKey);
        }

        // console.log("Your transaction signature", tx);
    }

    const getAssociatedTokenAccount = async (ownerPubkey: PublicKey, mintPk: PublicKey): Promise<PublicKey> => {
        let associatedTokenAccountPubkey = (await PublicKey.findProgramAddress(
            [
                ownerPubkey.toBuffer(),
                TOKEN_PROGRAM_ID.toBuffer(),
                mintPk.toBuffer(), // mint address
            ],
            ASSOCIATED_TOKEN_PROGRAM_ID
        ))[0];
        return associatedTokenAccountPubkey;
    }

    const initUserPool = async (
        userAddress: PublicKey,
    ) => {

        const [poolAddress] = await anchor.web3.PublicKey.findProgramAddress(
            [userAddress.toBuffer(), anchor.utils.bytes.utf8.encode("user-pool")],
            program.programId
        );
        //  console.log("poolAddress: ", poolAddress.toString());

        let userPoolKey = await PublicKey.createWithSeed(
            userAddress,
            "user-pool",
            program.programId,
        );
        //  console.log("userPoolKey: ", userPoolKey.toString());
        // return;
        //  console.log(USER_POOL_SIZE);
        let ix = SystemProgram.createAccountWithSeed({
            fromPubkey: userAddress,
            basePubkey: userAddress,
            seed: "user-pool",
            newAccountPubkey: userPoolKey,
            lamports: await connection.getMinimumBalanceForRentExemption(USER_POOL_SIZE),
            space: USER_POOL_SIZE,
            programId: program.programId,
        });
        //  console.log("ix: ", ix);
        const tx = await program.rpc.initializeUserPool(
            {
                accounts: {
                    userPool: userPoolKey,
                    owner: userAddress,
                },
                instructions: [
                    ix
                ],
                signers: []
            }
        );
        await connection.confirmTransaction(tx, "finalized");

        //  console.log("Your transaction signature", tx);
        let poolAccount = await program.account.userPool.fetch(userPoolKey);
        //  console.log('Owner of initialized pool = ', poolAccount.owner.toBase58());
    }



    // get STaked Token function
    // get STaked Token function
    // get STaked Token function
    // get STaked Token function



    const StakedNftPoolGet = async (userAddress: PublicKey) => {
        // const arr: any = [];
        let userPoolKey = await PublicKey.createWithSeed(
            userAddress,
            "user-pool",
            programId,
        );


        let firstNftTimeforRew = 0;


        let staked: any = [];
        try {
            // let poolState = await program.account.userPool.all()
            let poolState = await program.account.userPool.fetch(userPoolKey);
            // console.log(poolState.luvEntries.toNumber(), "LuvEntries");
            firstNftTimeforRew = poolState.firstNftTime.toNumber();

            staked = (poolState).stakedLuv.slice(
                0,
                (poolState).luvEntries.toNumber());
            // return poolState as UserPool;
        } catch (e) {
            // return null;
            // console.log(e, "lopipos")
        }

        setStakedNftTimeStamp(firstNftTimeforRew);
        // console.log("Now: ", Date.now()-(staked[0].stakeTime.toNumber()*1000));
        // console.log("Staked: ", staked[0].lockTime.toNumber());
        // console.log("Staked: ", staked[0].stakeTime.toNumber());
        // console.log("Current Date: ", Math.round(new Date().getTime() - staked[0].stakeTime.toNumber()*1000))
        console.log()
        setFtStakedToken(staked);


        let stakedNft4reward: any = [];
        let stakedNft4Multiplier: any = [];


        try {

            let poolState = await program.account.userPool.fetch(userPoolKey);
            // console.log("Pool State: ", poolState);
            // console.log("Pool State: ", poolState.stakedNftCount.toNumber());
            // console.log("Pool State: ", poolState.actualNftCount.toNumber());

            stakedNft4reward = poolState.stakedNftCount.toNumber();

            stakedNft4Multiplier = (poolState).stakedNfts.slice(0,
                (poolState).actualNftCount.toNumber());

            // return poolState as UserPool;
        } catch (e) {
            // return null;
            // console.log(e, "lopipos")
        }
        setStakedNfts(stakedNft4reward);

    }


    useEffect(() => {
        if (publicKey) {
            StakedNftPoolGet(publicKey);
        }

    }, [publicKey, stakedState])



    const CalTimeRew = (stakeFtReward: number, StakeTimeGet: number, stakedTokenNumbers: number) => {

        let now = (new Date().valueOf() / 1000);
        let rewTimeSec = now - StakeTimeGet;
        return rewTimeSec * stakeFtReward * stakedTokenNumbers;

    }

    const CalTimeRewWithNft = (stakeFtReward: number, stakeFtRewardWithNft: number, StakeTimeGet: number, stakedTokenNumbers: number) => {
        let now = (new Date().valueOf() / 1000);
        console.log("now: ",now)

        let rewTimeSec = stakedNftTimeStamp - StakeTimeGet;

        if (rewTimeSec < 0) { rewTimeSec = 0; } 

        let rewTimeWnft = now - stakedNftTimeStamp;

        let finalReward = (rewTimeSec * stakeFtReward * stakedTokenNumbers) + (rewTimeWnft * stakeFtRewardWithNft * stakedTokenNumbers);

        return finalReward;
    }

    const CalTimeRewDay = (stakeFtReward: number, stakedTokenNumbers: number) => {
        let now = (new Date().valueOf() / 1000);
        return stakeFtReward * stakedTokenNumbers * 86400;
    }

    const CalTimeRewDayWithNft = (stakeFtReward: number, stakeFtRewardWithNft: number, StakeTimeGet: number, stakedTokenNumbers: number) => {

        let now = (new Date().valueOf() / 1000);
        let rewTimeWnft = now - stakedNftTimeStamp;
        let rewTimeSec = stakedNftTimeStamp - StakeTimeGet;

        if (rewTimeSec < 0) { rewTimeSec = 0; }
        let finalReward;
        if (rewTimeSec > 86400) {
            // console.log("rewTimeSec")
            finalReward = ((stakeFtRewardWithNft * stakedTokenNumbers) * (86400));
        } else {
            // console.log("rewTimeSec2")
            finalReward = (rewTimeSec * stakeFtReward * stakedTokenNumbers) + ((stakeFtRewardWithNft * stakedTokenNumbers) * (86400 - rewTimeSec));
        }
        return finalReward;
    }



    const CalTimeMonth = (stakeFtReward: number, stakedTokenNumbers: number) => {
        let now = (new Date().valueOf() / 1000);
        return stakeFtReward * stakedTokenNumbers * 86400 * 30;
    }

    const CalTimeRewMonthWithNft = (stakeFtReward: number, stakeFtRewardWithNft: number, StakeTimeGet: number, stakedTokenNumbers: number) => {

        let now = (new Date().valueOf() / 1000);

        let rewTimeWnft = now - stakedNftTimeStamp;

        let rewTimeSec = stakedNftTimeStamp - StakeTimeGet;

        if (rewTimeSec < 0) { rewTimeSec = 0; }

        let finalReward;
        // console.log(rewTimeSec, "rewTimeSec")
        if (rewTimeSec > 86400) {
            // console.log("rewTimeSec")
            finalReward = ((stakeFtRewardWithNft * stakedTokenNumbers) * (86400) * 30);
        } else {
            // console.log("rewTimeSec 2")
            finalReward = (rewTimeSec * stakeFtReward * stakedTokenNumbers) + ((stakeFtRewardWithNft * stakedTokenNumbers) * (86400 - rewTimeSec) * (30));
        }

        return finalReward ;
    }



    useEffect(() => {
        CalTimeRew(1, 1, 0);

    }, [])




    // useEffect(() => {
    //     if(ftStakedToken?.length > 0)
    //     ftStakedToken.map((data: any) => {

    //         if(data.lockNumber.toNumber() === 1){
    //             console.log(estematedReward, "lopipo")
    //             if(stakedNfts === 0){
    //                 let result = CalTimeRew(11 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 1){
    //                 let result = CalTimeRew(25 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 2){
    //                 let result = CalTimeRew(53 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 3){
    //                 let result = CalTimeRew(78 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 4){
    //                 let result = CalTimeRew(104 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //         }
    //         if(data.lockNumber.toNumber() === 2){
    //             console.log(estematedReward, "lopipo")
    //             if(stakedNfts === 0){
    //                 let result = CalTimeRew(25 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 1){
    //                 let result = CalTimeRew(53 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 2){
    //                 let result = CalTimeRew(104 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 3){
    //                 let result = CalTimeRew(157 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 4){
    //                 let result = CalTimeRew(208 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //         }
    //         if(data.lockNumber.toNumber() === 3){
    //             console.log(estematedReward, "lopipo")
    //             if(stakedNfts === 0){
    //                 let result = CalTimeRew(38 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 1){
    //                 let result = CalTimeRew(78 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 2){
    //                 let result = CalTimeRew(157 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 3){
    //                 let result = CalTimeRew(233 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //             if(stakedNfts === 4){
    //                 let result = CalTimeRew(313 / 10000000000, data.stakeTime.toNumber(),data.amount.toNumber()).toFixed(5);
    //                 setEstematedReward(estematedReward + parseFloat(result));
    //             }
    //         }
    //     })
    // }, [ftStakedToken])






    // Unstake luv token function
    // Unstake luv token function
    // Unstake luv token function
    // Unstake luv token function



    const unstakeLuv = async (userAddress: PublicKey, mint: PublicKey, id: any) => {
        console.log("ID: ",id)
        const [globalAuthority, bump] = await PublicKey.findProgramAddress(
            [Buffer.from(GLOBAL_AUTHORITY_SEED)],
            program.programId
        );
        let rewardVault = await getAssociatedTokenAccount(globalAuthority, REWARD_TOKEN_MINT);

        let userTokenAccount = await getAssociatedTokenAccount(userAddress, REWARD_TOKEN_MINT);

        let userPoolKey = await PublicKey.createWithSeed(
            userAddress,
            "user-pool",
            program.programId,
        );

        let poolAccount = await connection.getAccountInfo(userPoolKey);
        // await initUserPool(userAddress);
        if (poolAccount === null || poolAccount.data === null) {

            await initUserPool(userAddress);
            return;
        }

        // const ONE_PERCENT_WALLET = new PublicKey('41NhinBZAQHsGcqC6pPYpy97D255nYC2ZPnWVaLqacRJ');


        try {
            const tx = await program.rpc.withdrawLuvFromPool(
                bump, new anchor.BN(id), {
                accounts: {
                    owner: userAddress,
                    userPool: userPoolKey,
                    globalAuthority,
                    userTokenAccount,
                    rewardVault,
                    tokenProgram: TOKEN_PROGRAM_ID,
                },
                instructions: [
                    // SystemProgram.transfer({
                    //     fromPubkey: userAddress,
                    //     toPubkey: ONE_PERCENT_WALLET_30,
                    //     lamports: 6000000,
                    // }),
                    // SystemProgram.transfer({
                    //     fromPubkey: userAddress,
                    //     toPubkey: ONE_PERCENT_WALLET_70,
                    //     lamports: 14000000,
                    // }),
                ],
                signers: [],
            }
            );
            await connection.confirmTransaction(tx, "singleGossip");
            toast.success("Successfully Unstaked");
        }
        catch (e: any) {
            // console.log(e,"error msg")
            toast.error(`Error: LUV Unstake Failed ${e.message}`);
        }




        if (publicKey) {
            StakedNftPoolGet(publicKey);
            getWallet();
        }
        // console.log("Your transaction signature", tx);
    }



    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    return (
        <>
            <ToastContainer />

            <Typography variant="h3" sx={{ color: 'white', fontFamily: 'Poppins', display: 'flex', justifyContent: 'center', lineHeight: '3', fontWeight: 'bold' }}>
                Your Wallet

            </Typography>
            <Grid container justifyContent="space-evenly" alignItems="center" style={{ gap: 1 }}>
                <Grid item xs={10} sm={10} md={6} lg={2} className="MainInforBox" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", fontFamily: 'Poppins', fontSize: '20px', marginBottom: '20px' }}>
                    <span>Your Wallet</span>
                    {/* <br /> */}
                    <>
                        <p
                            style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '16px' }}
                        >
                            {wallet?.publicKey?.toString().slice(0, 4)}. . . .{wallet?.publicKey?.toString().slice(40)}
                        </p>
                    </>
                </Grid>
                <Grid item xs={10} sm={10} md={6} lg={2} className="MainInforBox" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: "center", fontFamily: 'Poppins', fontSize: '16px', marginBottom: '20px' }}>
                    <span style={{ fontSize: '20px' }}>Multipliers staked</span>
                    {/* <br /> */}
                    Cozy Tokens:   {count} <br />
                    Cured Tokens: {count1}
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={2} className="MainInforBox" style={{ display: 'flex', justifyContent: 'center', alignItems: "center", fontFamily: 'Poppins' }}>
                    Estimated Rewards<br/>
                    {estematedReward}
                </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6} lg={2} className="MainInforBox" style={{ display: 'flex', justifyContent: 'center', alignItems: "center", fontFamily: 'Poppins' }}>
                    APY rate<br/>
                    
                </Grid> */}
            </Grid>

            <div className='btnFTpageMain'>
                <Button variant='contained'
                    onClick={() => setStakedState(true)}
                    style={stakedState ?
                        {
                            fontWeight: '600', fontSize: '20px', color: 'white', fontFamily: 'Poppins',
                            backgroundColor: '#e83f88', maxWidth: '330px', width: '100%', borderRadius: '30px'
                        }
                        :
                        {
                            fontWeight: '600', fontSize: '20px', color: 'white', fontFamily: 'Poppins',
                            backgroundColor: '#C390A2', maxWidth: '330px', width: '100%', borderRadius: '30px'
                        }
                    }
                >
                    Stake Luv
                </Button>
                <Button variant='contained'
                    onClick={() => setStakedState(false)}
                    style={stakedState ?
                        {
                            fontWeight: '600', fontSize: '20px', color: 'white', fontFamily: 'Poppins',
                            backgroundColor: '#C390A2', maxWidth: '330px', width: '100%', borderRadius: '30px'
                        }
                        :
                        {
                            fontWeight: '600', fontSize: '20px', color: 'white', fontFamily: 'Poppins',
                            backgroundColor: '#e83f88', maxWidth: '330px', width: '100%', borderRadius: '30px'
                        }
                    }
                >
                    View Staked Luv
                </Button>
            </div>

            {wallet?.connected ?
                <>
                    <div
                        style={{
                            minHeight: '400px'
                            // ,overflow: 'auto',maxHeight: '400px'
                        }}
                    >



                        {stakedState ?
                            <>
                                {ftTokens && ftTokens ?
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                        {/* <Typography variant="h6">You contains {ftTokens} FT Token: </Typography> */}
                                        <Typography variant="h5" style={{ color: 'white', marginBottom: '10px', fontFamily: 'Poppins' }}>Your wallet contain {Math.round(ftTokens)} LUV Token </Typography>



                                        <div
                                            style={{
                                                display: 'flex', height: '320px', width: '320px', justifyContent: 'center', backgroundSize: 'contain, cover',
                                                // backgroundImage: 'url(/Group1.png)',
                                                // backgroundImage: 'url(/luv-image.jpeg)',
                                                backgroundColor: 'rgb(0 0 0 / 38%)',
                                                backgroundRepeat: 'no-repeat', backgroundPosition: 'center', borderRadius: '20px',
                                                flexDirection: 'column', alignItems: 'center'
                                            }}
                                        >

                                            <input
                                                type="number"
                                                name="example"
                                                placeholder="Enter Token"
                                                min="0"
                                                onChange={(e) => {
                                                    // console.log(e.target.value)
                                                    setNumberToken(Number(e.target.value))

                                                }}

                                                style={{
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', marginBottom: '10px',
                                                    padding: '10px', borderRadius: '15px', borderColor: 'transparent', fontFamily: 'Poppins', fontSize: '16px', width: '250px'
                                                }}
                                            />

                                            <FormControl>
                                                <FormLabel id="demo-controlled-radio-buttons-group" style={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: 'bolder', color: 'white' }}>
                                                    Duration</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={value}
                                                    onChange={handleChange}
                                                >
                                                    <FormControlLabel style={{ color: 'white', fontFamily: 'Poppins' }} value={1} control={<Radio />} label="30 days" />
                                                    <FormControlLabel style={{ color: 'white', fontFamily: 'Poppins' }} value={2} control={<Radio />} label="60 days" />
                                                    <FormControlLabel style={{ color: 'white', fontFamily: 'Poppins' }} value={3} control={<Radio />} label="90 days" />

                                                </RadioGroup>
                                            </FormControl>

                                        </div>

                                        <Button variant='contained'
                                            style={{ fontWeight: '600', marginTop: '25px', fontSize: '20px', color: 'white', fontFamily: 'Poppins', backgroundColor: '#C390A2', maxWidth: '330px', width: '100%', borderRadius: '30px' }}
                                            onClick={() => stakeLuv(ftTokensPubKeyADD, ftTokensMintADD, numberToken, value)
                                            }
                                        >
                                            stake
                                        </Button>

                                    </div>
                                    :
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                                        <Typography variant="h5" style={{ color: 'white', marginBottom: '10px', fontFamily: 'Poppins' }}>You don't have any LUV token </Typography>
                                    </div>
                                }


                            </>
                            :
                            <>
                                {ftStakedToken && ftStakedToken.length != 0 ?
                                    <div
                                    // style={{minHeight: '400px',overflow: 'auto',maxHeight: '400px'}}
                                    >

                                        <Grid container justifyContent="space-evenly" alignItems="center" >
                                            {ftStakedToken.map((data: any, key:any) => (
                                                <>
                                                    {/* {console.log(data.item.toNumber(), "itemNumber:")} */}

                                                    <Grid item sm={12} md={6} lg={4} xl={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", fontFamily: 'Poppins' }}>
                                                        <div
                                                            style={{
                                                                display: 'flex', height: '300px', width: '300px', justifyContent: 'center', backgroundSize: 'contain, cover',
                                                                backgroundImage: 'url(/Group1.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', borderRadius: '20px',
                                                                flexDirection: 'column', alignItems: 'center'
                                                            }}
                                                        >

                                                            <p
                                                                style={{ fontWeight: '600', textAlign: 'center', padding: '7px', fontSize: '20px', color: 'white', fontFamily: 'Poppins', backgroundColor: '#C390A2', maxWidth: '270px', width: '100%', borderRadius: '30px' }}
                                                            >
                                                                {data.amount.toNumber()} LUV tokens <br />

                                                                {data.lockNumber.toNumber() == 1 ? <>30</> : <></>}
                                                                {data.lockNumber.toNumber() == 2 ? <>60</> : <></>}
                                                                {data.lockNumber.toNumber() == 3 ? <>90</> : <></>}

                                                                {' '}days lock-in</p>
                                                        </div>

                                                        <Button
                                                            variant='contained'
                                                            // onClick={() => { unstakeLuv(ftTokensPubKeyADD, ftTokensMintADD, data.item.toNumber()) }}
                                                            onClick={() => { unstakeLuv(ftTokensPubKeyADD, ftTokensMintADD, key) }}
                                                            style={{ fontWeight: '600', marginTop: '25px', marginBottom: '25px', fontSize: '20px', color: 'white', fontFamily: 'Poppins', backgroundColor: '#C390A2', maxWidth: '250px', width: '100%', borderRadius: '30px' }}
                                                        >
                                                            Unstake
                                                        </Button>
                                                        <p style={{ color: 'white' }}>
                                                            {data.lockNumber.toNumber() == 1 ?
                                                                <>
                                                                    {
                                                                        stakedNfts === 0 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:   {CalTimeRew(1.1 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(5)} <br />
                                                                                Estimated Reward:     {CalTimeRewDay(1.1 / 1000000000, data.amount.toNumber()).toFixed(5)}/day<br />
                                                                                Estimated Reward:     {CalTimeMonth(1.1 / 1000000000, data.amount.toNumber()).toFixed(5)}/month<br />
                                                                                APY rate: upto 4%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts === 1 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:   {CalTimeRewWithNft(1.1 / 10000000000, 2.5 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward:     {CalTimeRewDayWithNft(1.1 / 1000000000, 2.5 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:       {CalTimeRewMonthWithNft(1.1 / 1000000000, 2.5 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 8%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts === 2 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:   {CalTimeRewWithNft(1.1 / 10000000000, 5.3 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward: {CalTimeRewDayWithNft(1.1 / 1000000000, 5.3 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:   {CalTimeRewMonthWithNft(1.1 / 1000000000, 5.3 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 17%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts === 3 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:      {CalTimeRewWithNft(1.1 / 10000000000, 7.8 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward:    {CalTimeRewDayWithNft(1.1 / 1000000000, 7.8 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:      {CalTimeRewMonthWithNft(1.1 / 1000000000, 7.8 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 25%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts >= 4 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:      {CalTimeRewWithNft(1.1 / 10000000000, 10.4 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward:    {CalTimeRewDayWithNft(1.1 / 1000000000, 10.4 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:      {CalTimeRewMonthWithNft(1.1 / 1000000000, 10.4 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 33%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </>
                                                                :
                                                                <></>
                                                            }


                                                            {data.lockNumber.toNumber() == 2 ?
                                                                <>
                                                                    {
                                                                        stakedNfts === 0 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:      {CalTimeRew(2.5 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(5)} <br />
                                                                                Estimated Reward:    {CalTimeRewDay(2.5 / 1000000000, data.amount.toNumber()).toFixed(5)}/day<br />
                                                                                Estimated Reward:      {CalTimeMonth(2.5 / 1000000000, data.amount.toNumber()).toFixed(5)}/month<br />
                                                                                APY rate: upto 8%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts === 1 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:      {CalTimeRewWithNft(2.5 / 10000000000, 5.3 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward:    {CalTimeRewDayWithNft(2.5 / 1000000000, 5.3 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:      {CalTimeRewMonthWithNft(2.5 / 1000000000, 5.3 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 17%
                                                                                {/* {setApyRateSum( apyRateSum+17)} */}

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts === 2 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:      {CalTimeRewWithNft(2.5 / 10000000000, 10.4 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward:    {CalTimeRewDayWithNft(2.5 / 1000000000, 10.4 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:      {CalTimeRewMonthWithNft(2.5 / 1000000000, 10.4 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 33%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts === 3 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:      {CalTimeRewWithNft(2.5 / 10000000000, 15.7 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward:    {CalTimeRewDayWithNft(2.5 / 1000000000, 15.7 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:      {CalTimeRewMonthWithNft(2.5 / 1000000000, 15.7 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 50%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts >= 4 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:   {CalTimeRewWithNft(2.5 / 10000000000, 20.8 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward: {CalTimeRewDayWithNft(2.5 / 1000000000, 20.8 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:   {CalTimeRewMonthWithNft(2.5 / 1000000000, 20.8 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 66%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </>
                                                                :
                                                                <></>
                                                            }


                                                            {data.lockNumber.toNumber() == 3 ?
                                                                <>
                                                                    {
                                                                        stakedNfts === 0 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:   {CalTimeRew(3.8 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(5)} <br />
                                                                                Estimated Reward: {CalTimeRewDay(3.8 / 1000000000, data.amount.toNumber()).toFixed(5)}/day<br />
                                                                                Estimated Reward:   {CalTimeMonth(3.8 / 1000000000, data.amount.toNumber()).toFixed(5)}/month<br />
                                                                                APY rate: upto 12%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts === 1 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:   {CalTimeRewWithNft(3.8 / 10000000000, 7.8 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward: {CalTimeRewDayWithNft(3.8 / 1000000000, 7.8 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:   {CalTimeRewMonthWithNft(3.8 / 1000000000, 7.8 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 25%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts === 2 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:   {CalTimeRewWithNft(3.8 / 10000000000, 15.7 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward: {CalTimeRewDayWithNft(3.8 / 1000000000, 15.7 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:   {CalTimeRewMonthWithNft(3.8 / 1000000000, 15.7 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 50%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts === 3 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:      {CalTimeRewWithNft(3.8 / 10000000000, 23.3 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward:    {CalTimeRewDayWithNft(3.8 / 1000000000, 23.3 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:      {CalTimeRewMonthWithNft(3.8 / 1000000000, 23.3 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 74%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    {
                                                                        stakedNfts >= 4 ?
                                                                            <>
                                                                                Time to Unstake: <Countdown date={Date.now() + ((data.lockTime*1000)-(Date.now()-(data.stakeTime.toNumber()*1000)))} /><br/>
                                                                                Current Reward:      {CalTimeRewWithNft(3.8 / 10000000000, 31.3 / 10000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)} <br />
                                                                                Estimated Reward:    {CalTimeRewDayWithNft(3.8 / 1000000000, 31.3 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/day<br />
                                                                                Estimated Reward:      {CalTimeRewMonthWithNft(3.8 / 1000000000, 31.2 / 1000000000, data.stakeTime.toNumber(), data.amount.toNumber()).toFixed(7)}/month<br />
                                                                                APY rate: upto 99%

                                                                            </>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </>
                                                                :
                                                                <></>
                                                            }

                                                        </p>

                                                    </Grid>
                                                </>
                                            )

                                            )
                                            }
                                        </Grid>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

                                        <Typography variant="h5" style={{ color: 'white', marginBottom: '10px', fontFamily: 'Poppins' }}>No tokens are staked. </Typography>
                                    </div>
                                }



                            </>
                        }

                    </div>
                    <NFTStaking setCount={setCount} setCount1={setCount1} />




                </>
                :
                <>
                    <Typography variant="h5"
                        style={{
                            maxWidth: 'max-content', color: 'white', flexDirection: 'column', fontFamily: 'Poppins',
                            display: 'flex', justifyContent: 'center', lineHeight: '3', fontWeight: 'bold', alignItems: 'center', margin: 'auto'
                        }}>
                        Connect Phantom Wallet
                        {wallet?.connected ?
                            <WalletDisconnectButton className="nav1-connect-btn" />
                            :
                            <WalletMultiButton className="nav1-connect-btn" />
                        }
                    </Typography>
                </>
            }
        </>
    );
}

export default FTStaking;